import React from 'react'
import { Link } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import Layout from '../components/layout'
import SEO from '../components/seo'
import pdf from '../assets/resume.pdf'

const About = () => (
  <Layout>
    <SEO title="Info" />
    <div className="flex-container">
    <div className="col-7">
    <h1> About </h1>

    <p> I'm Violet Lingenfelter. I'm originally from Santa Cruz, California. I got a degree 
    in Environmental Science focused on geographic information systems (GIS) from Northeastern University
     in 2021.
     </p>
     <p> In my free time, I like to knit, travel, and hike. 
     If you want to see what I'm knitting, ou can check out my <OutboundLink href="https://www.ravelry.com/projects/shylerlover">Ravelry projects</OutboundLink>.
     </p>

     </div>
     <div className="col-5">
     <h1> Contact </h1>
     <p>
     Send me an email at <a href="mailto:vlingenfelter5@gmail.com">vlingenfelter5@gmail.com</a>
     <br />
     See some of my code on <OutboundLink href="https://github.com/vlingenfelter">GitHub</OutboundLink>
     <br />
     See some of my photos on <OutboundLink href="https://www.instagram.com/vlingenfelter/">Instagram</OutboundLink>
     <br />
     Or check out my <Link to='/coloring/'>coloring book pages</Link>.
     </p>
     <hr />
     <p>
     If you want, you can also take a look at my <a href={pdf}> resume </a>
     </p>
     </div>
     </div>
  </Layout>
)

export default About
